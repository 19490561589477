<section id="references">
    <div class="container  mb-5" >
        <h2><strong>Recommandations</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="card shadow-lg  rounded pb-3" data-aos="fade-right" data-aos-delay="300">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="not-active active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1" class="not-active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2" class="not-active"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="d-block w-100">
                            <div class="row">
                                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                                    <div class="row">
                                        <div class="mt-4">
                                            <img class="d-block w-100 rounder" src="assets/images/unknown.jpg"
                                                alt="Second slide">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-3">
                                            <h5>Velghe Walter</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                            <h5><small>IT Plant Manager</small></h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                            <span class="text-uppercase"><strong>Agc glass europe</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                                    <div class="row">
                                        <span>Zinedine est trés éfficace dans le développement d'applications et dans leurs maintenance, innovant, et rationnel. Codage comme
                                            mode de vie, son travail présente des produits de grande qualité, suivant les
                                            normes et les bonnes pratiques. Il a réalisé trois projets sur notre site et je suis
                                            tellement impressionné par ses techniques. Il a toujours une pensée critique. Il est toujours prêt à prendre les défis. 
                                         
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="d-block w-100">
                            <div class="row">
                                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                                    <div class="row">
                                        <div class="mt-4">
                                            <img class="d-block w-100 rounder"
                                                src="assets/images/lotfi-benkhider.jpg" alt="Second slide">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-3">
                                            <h5>Benkhider Lotfi</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                            <h5><small>CEO & Founder</small></h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                            <span class="text-uppercase"><strong>Scotfy</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                                    <div class="row">
                                        <span>Zinedine a éffictué son stage dans mon entreprise. Il est autonome et dispose d'une grande capacité d'apprentissage.
                                        Il respecte les délais des livrables. Il a l'ésprit d'équipe. Zinedine n'hesite pas à proposer de nouvelles idées pertinentes. Il respecte les consignes, son code est propore.     
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="d-block w-100">
                            <div class="row">
                                <div class="col-lg-2 ml-4 mr-4 col-sm-12">
                                    <div class="row">
                                        <div class="mt-4">
                                            <img class="d-block w-100 rounder" src="assets/images/mohamed-yahiatene.jpg"
                                                alt="Second slide">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-3">
                                            <h5>Yahiatene Mohamed</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                         
                                            <h5><small>DevOps Engineer</small></h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mt-2 ">
                                            <span class="text-uppercase"><strong>AG2R La Mondiale</strong></span>
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="card-body ml-4 mr-3 mt-4 col-lg-9 col-sm-12">
                                    <div class="row">
                                        <span>J'ai travaillé deux ans avec Zinedine à l'université de Lille.

                                            Zinedine est un très bon partenaire de travail avec de hautes connaissances en développement backend et frontend, 
                                            il est toujours soucieux d'aller de l'avant sans jamais avoir peur d'utiliser de nouvelles technologies. 
                                            </span><br>
                                        <p>Il n'hesite pas à aider ses collegues chaque fois qu'il le peut. Il arrive à résoudre les problèmes de manière rapide et efficace avec un optimisme typique.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>