<section id="about">
    <div class="container">
        <h2><strong>Sobre</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-12" data-aos="fade-right" >

                <picture>
                    <img src="assets/images/perfil.jpg" alt="foto de perfil" class="img-fluid">
                </picture>
               <div class="d-flex justify-content-center">
                    <a  class="btn-social-media" href="https://www.linkedin.com/in/vaniltonpinheiro" rel="tooltip" target="_blank"  title="Suivez moi sur Linkedin">
                        <i  class="fa fa-linkedin fa-lg "></i>
                    </a>
                    <a class="btn-social-media btn-default btn-round btn-lg btn-icon" href="https://github.com/Vanilton18" rel="tooltip" target="_blank" title="Meu GitHub" >
                        <i  class="fa fa-github fa-lg "></i></a>
                    <a class="btn-social-media btn-default btn-round btn-lg btn-icon" href="https://www.instagram.com/vaniltonpinheiro" rel="tooltip" target="_blank" title="Meu Instagram" >
                            <i  class="fa fa-instagram fa-lg "></i></a>    
                </div>
               
              
            </div>
            <div class="col-lg-8  col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
                <h3>Analista de Teste e Sistema</h3>
                <p class="font-italic">
                    Sou <b>apaixonado por desenvolvimento de software</b> e tudo que o envolve como Cliente, Usuários, Times, Metodologias, 
                    Tecnologias e Ferramentas. Tenho uma atenção especial para a área de Teste de Software por onde adentrei como QA,
                    possuo um grante <b>prazer em compartilhar tudo que adquiro de conhecimento</b>. 
                    Além do que já descrevi existe também um propósito 
                    de <b>fazer tudo</b> isto <b>com muito bom humor</b> levando alegria para as pessoas. 
                </p>
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="mapMarker"></fa-icon><strong> Manaus, Amazonas</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="building"></fa-icon><strong> FPF Tech, Brasil</strong>
                            </li>
                            <li>
                                <fa-icon [icon]="email"></fa-icon><strong><a href="mailto:vanilton18@gmail.com">
                                        vanilton18@gmail.com</a></strong>
                            </li>
                       
                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <fa-icon [icon]="birthday"></fa-icon><strong> {{ this.getCurrentAge() }} anos  </strong>
                            </li>
                            <li>
                                <fa-icon [icon]="badge"></fa-icon><strong> Bacharel em Ciência da Computação</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <p>
                    Conhecimento em metodologias ágeis e aplicação da mesma em projetos de software. 
                    Aplicação de técnicas de teste Caixa Preta, Branca e Cinza. Atualmente exercendo o papel de ScrumMaster na 
                    <a href="http://fpftech.com" target="_blank">FPFTech</a>.
                </p>
            </div>
        </div>
    </div>
</section>