<section id="cv">
    <div class="container">
        <h2><strong>Curriculum Vitae</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>Educação</strong></h4>

                <ul class="timeline">
                    <li>
                        <h5><strong>Especialização</strong> </h5>
                        <em class="font-italic">Gestão de Pessoas: Carreiras, Liderança e Coaching <br> PUCRS, Brasil</em>
                        <p class="year p-2 d-flex justify-content-center">2020 - 2021</p>
                    </li>
                    <li>
                        <h5>Especialização</h5>
                        <em class="font-italic">Engenharia de Software com Ênfase em Desenvolvimento Web <br> Centro Universitário do Norte - UNINORTE, Brasil</em>
                        <p class="year p-2 d-flex justify-content-center">2014 - 2015</p>
                    </li>
                    <li>
                        <h5>Bacharelado</h5>
                        <em class="font-italic">Ciência da Computação <br> Centro Universitário do Norte - UNINORTE, Brasil</em>
                        <p class="year p-2 d-flex justify-content-center">2009 - 2013</p>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>Experiências</strong></h4>
                <ul class="timeline">
                    <li>
                        <h5>Pessoas e Processos</h5>
                        <em>FPF Tech, Manaus, Brasil</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">2022 - Atual</p>
                            <button (click)="detailOnClick('freelanceScotfy_detail')" type="button" class="btn btn-primary">+</button>
                        </div>
                        <p *ngIf="freelanceScotfyIsShown">

                            <strong>Tarefa 1:</strong> Facilitar Team Building. <br>
                            <strong>Tarefa 2:</strong> Colaborar com o desenvolvimento da carreira profissional dos colaboradores.​ <br>
                            <strong>Tarefa 3:</strong> Promover o compartilhamento de experiências​ e feedbacks para o desenvolvimento técnico e comportamental dos colaboradores.<br>
                            <strong>Tarefa 4:</strong> Orientar os colaboradores na correta execução dos processos organizacionais​. <br>
                            <strong>Tarefa 5:</strong> Coletar e comunicar métricas de desempenho do time​. <br>
                            <strong>Tarefa 6:</strong> Demandar aquisições​. <br>
                            <strong>Tarefa 7:</strong> Seleção técnica de candidatos para contratação​. <br>
                            
                            <strong>Competências aplicadas:</strong> Coach, Gestão de Pessoas e Liderança
                        </p>
                    </li>
                    <li>
                        <h5>Analista de Sistema</h5>
                        <em>FPF Tech, Manaus, Brasil</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">2021 - 2022</p>
                            <button (click)="detailOnClick('freelanceScotfy_detail')" type="button" class="btn btn-primary">+</button>
                        </div>
                        <p *ngIf="freelanceScotfyIsShown">

                            <strong>Tarefa 1:</strong> Levantamento de Requisitos de Software. <br>
                            <strong>Tarefa 2:</strong> Priorização de demandas para equipe de Desenvolvimento. <br>
                            <strong>Tarefa 3:</strong> Alinhamento de Expectativas com cliente.<br>
                            <strong>Tarefa 4:</strong> Desenvolvimento de Pessoas Soft e Hard Skills. <br>
                            <strong>Tecnologias:</strong> Python, Java, Angular, Angular Materials, pgSql, Oracle, React, .NetCore
                        </p>
                    </li>
                    <li>
                        <h5>Analista de Teste</h5>
                        <em>FPF Tech, Manaus, Brasil</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">2012 - 2021 (9 anos)</p>
                            <button (click)="detailOnClick('freelance_detail')" type="button" class="btn btn-primary">+</button>
                        </div>
                        <p *ngIf="freelanceIsShown">
                            <strong>Tarefa 1:</strong> Teste de Software: Especificação, Execução, Identificação de Defeitos.<br>
                            <strong>Tarefa 2:</strong> Automação de Testes (Funcional, Aceitação e Performance). <br>
                            <strong>Tarefa 3:</strong> Teste Exploratório utilizando sessão. <br>
                            <strong>Tecnologias:</strong> JAVA, .Net, Python, JUnit, Unittest, Selenium WebDriver, Sikuli, Appium, JMeter, Cucumber JVM
                        </p>
                    </li>
                    <li>
                        <h5>Professor Especialização - Curso Teste Ágil</h5>
                        <em>CESAR School, Recife, Brasil</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">Out/2021 (1 Turma)</p>
                            <button (click)="detailOnClick('alternance_detail')" type="button" class="btn btn-primary">+</button>
                        </div>
                        <p *ngIf="alternanceIsShown">
                            <strong>Tarefa 1:</strong> Disciplina de Teste de Performance. <br>
                            <strong>Modelo de Aula:</strong>  Online
                        </p>
                    </li>
                    <li>
                        <h5>Professor Especialização - Curso Engenharia de Software com Ênfase em Desenvolvimento WEB</h5>
                        <em>Centro Universitário do Norte - UNINORTE, Manaus, Brasil</em>
                        <div class="d-flex flex-row">
                            <p class="year p-2 d-flex justify-content-center">2017 - 2019 (3 Turmas)</p>
                            <button (click)="detailOnClick('stage_detail')" type="button" class="btn btn-primary">+</button>
                        </div>
                        <p *ngIf="stageIsShown">
                            <strong>Tarefa 1:</strong> Disciplina de Testes em Aplicações WEB. <br>
                            <strong>Modelo de Aula:</strong>  Presencial
                        </p>
                    </li>

                </ul>
            </div>
            <div class="col-lg-4 col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div class="container row">
                    <div class="aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <h4><strong>Idiomas</strong></h4>
                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/brasil-flag.svg" width="20"
                                    alt="drapeau de la france"><strong> Português </strong> <i class="val">Nativo</i></span>
                        </div>

                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/united-kingdom-flag.png" width="20"
                                    alt="drapeau du royaume uni"><strong> Inglês </strong> <i class="val"> Intermediário </i></span>
                        </div>

                

                    </div>
                </div>
                <div class="container row">
                    <div class="mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                        <h4><strong>Outros Interesses</strong></h4>
                        <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="musicIcon"></fa-icon><strong> Música </strong> <i class="val"> Iniciante em Bateria</i>
                            </span>
                        </div>

                        <div class="noProgress langue">
                            <span>
                                <fa-icon [icon]="gameIcon"></fa-icon><strong> Video Game </strong> <i class="val">
                                    Super Nintendo s2 e Mario Kart 8 Wii U s2 </i>
                            </span>
                        </div>

                    </div>
                </div>


            </div>

        </div>
    </div>
</section>