

<div class="page-content">
    <app-header></app-header>
    <app-home></app-home>
    <app-about></app-about>
    <app-competences></app-competences>
    <app-education></app-education>
    <app-projects></app-projects>
    <!--<app-references></app-references>-->
    <!--<app-contact></app-contact>-->
</div>
