<section id="competences">
    <div class="container">
        <h2><strong>Competências</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row mt-2">
                <div class="col-lg-4" data-aos="fade-up "
                data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                    <h5 class="h5"><strong>Tecnologias</strong></h5>
                    <div id="skillgraph">
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Java</span>
                                <span class="skill-percent">70%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='good skillData bg-rust'></span></span>
                        </div>  
                        <div class='row skill-row'>
                   
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Python</span>
                                <span class=" skill-percent">60%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='average skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>.Net, PHP, JavaScript</span>
                                <span class=" skill-percent">40%</span>
                            </div>
                           
                            <span class='skillData-Wrapper'>
                            <span class='low skillData bg-rust' ></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>BootStrap, Angular</span>
                                <span class="skill-percent">40%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='low skillData bg-rust' ></span></span>
                        </div>
                        
                    </div>

                </div>
                <div class="col-lg-4" data-aos="fade-down"
                data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                    <h5 class="h5"><strong>Ferramentas</strong></h5>
                    <div id="skillgraph" class="panel panel-default">
                        <div class='row skill-row' >
                            <div class="label d-flex flex-row" >
                                <span class="skillLabel">Selenium, Playwright Appium, Sikuli, Jmeter</span>
                                <span class="skill-percent">95%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Git</span>
                                <span class="skill-percent">90%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust' ></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Office</span>
                                <span class="skill-percent">90%</span>
                            </div>
                          
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust'></span></span>
                        </div>
                        
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Linux, Windows</span>
                                <span class="skill-percent">80%</span>
                            </div>
                     
                            <span class='skillData-Wrapper'>
                            <span class='very-good skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>PgSql</span>
                                <span class="skill-percent">70%</span>
                            </div>
                            
                            <span class='skillData-Wrapper'>
                            <span class='good skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Docker</span>
                                <span class="skill-percent">50%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='average skillData bg-rust' ></span></span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" data-aos="fade-up"
                data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                    <h5 class="h5"><strong>Metodologias</strong></h5>
                    <div id="skillgraph" class="panel panel-default">
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Scrum</span>
                                <span class=" skill-percent">90%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust'></span></span>
                        </div>
                        
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Kanban</span>
                                <span class="skill-percent">90%</span>
                            </div>
                  
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust' data-percent='90'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Design Thinking</span>
                                <span class="skill-percent">70%</span>
                            </div>
                          
                            <span class='skillData-Wrapper'>
                            <span class='very-good skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Lean</span>
                                <span class="skill-percent">70%</span>
                            </div>
                     
                            <span class='skillData-Wrapper'>
                            <span class='very-good skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>DevOps</span>
                                <span class="skill-percent">50%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='average skillData bg-rust'></span></span>
                        </div>
                    </div>
                </div>
              
        </div>
    </div>

</section>