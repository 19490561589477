<section id="contact">
    <div class="container mb-5">
        <h2><strong >Contato</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row"  data-aos="fade-up"
        data-aos-anchor-placement="top-center">
            <div class="col-md-3 col-lg-3">
                <div class="contact-info">
                    <img src="assets/images/contact-image.png" alt="image" />
        
                    <h4>Entre em contato comigo</h4>
                </div>
            </div>
            <div class="col-md-9">
                <div class="contact-form">
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="identite">Nome:</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="identite" placeholder="Entre com seu nome"
                                name="fname">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="email">E-mail:</label>
                        <div class="col-sm-10">
                            <input type="email" class="form-control" id="email" placeholder="Entre com seu E-mail" name="email">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="object">Assunto:</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="object" placeholder="Entre com o assunto"
                                name="object">
                        </div>
                    </div>
                  
                    <div class="form-group">
                        <label class="control-label col-sm-2" for="comment">Mensagem:</label>
                        <div class="col-sm-10">
                            <textarea class="form-control" rows="5" id="comment"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-2 col-sm-10">
                            <button type="submit" class="btn btn-default">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>