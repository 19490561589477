<section id="portfolio">
    <div class="container" >
        <h2><strong>Portfolio</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
        
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg p-3 mb-5 bg-white rounded">
                    <div  class="card-header d-flex flex-column " style="height: 300px;width: 100%; "  >    
                        <div id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;  " [ngStyle]="{backgroundImage:'url(assets/images/paper-publish.webp)'}" >
                        </div>
                        <div id="teamTraveler-link" class="links mt-auto d-flex justify-content-center">
                            <a href="https://sol.sbc.org.br/index.php/sbqs/article/view/15297"  title="Detalhe do artigo" target="_BLANK"><i class="bx bx-zoom-in bx-md"></i></a>
                            <!--<a href="assets/images/voyages.webp" data-gall="teamTravelerGallery" class="venobox vbox-item" title="Detalhe"></a>
                            <a href="assets/images/logements.webp" data-gall="teamTravelerGallery" class="venobox vbox-item" title="Mes Logements"></a>-->
                            <a href="https://sol.sbc.org.br/index.php/sbqs/issue/view/736" title="Anais do Evento" target="_BLANK"><i class="bx bx-link bx-md"></i></a>
                        </div>
                    </div>

                    <div class="card-body">
                            <h3 class="card-title">Publicação</h3>
                            <h5 class="card-title"><strong>
                                Especificação e Automação Colaborativas de Testes utilizando a técnica BDD - SBQS 2013</strong>  </h5>
                            <p class="card-text"> Java, Cucumber JVM, JUnit, Selenium WebDriver<br><br></p>
                            <div class="d-flex flex-row justify-content-center">
                                <!--<a href="https://github.com/zinedineBenkhider/team_traveler" target="_blank" target="_blank" type="button" class="btn btn-primary mr-3">Code Source</a> -->
                                <button (click)="detailOnClick('teamTraveler')" type="button" class="btn btn-primary">Resumo</button>
                            </div>
                            <div *ngIf="teamTravelerIsShown" class="mt-3">
                                <p>Teste é uma atividade indispensável no processo de desenvolvimento de software. Porém, sua execução manual tem um alto custo e, muitas vezes o cliente requer um feedback rápido, assim comprometendo a qualidade do produto. Uma forma de atuar nesse problema é por meio do uso do BDD (Behavior-Driven Development), em conjunto com o framework para automatização de testes web Selenium WebDriver. Este artigo apresenta um relato de experiência da utilização dessa técnica com automatização de testes no desenvolvimento de um software, permitindo a colaboração entre os 
                                    integrantes do time, aumentando a qualidade do software e dando uma resposta ágil ao cliente.</p>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12"  data-aos="flip-up">
                <div class="card shadow-lg p-3 mb-5 bg-white rounded">

                    <div  class="card-header d-flex flex-column " style="height: 300px;width: 100%; "  >    
                        <div  id="mautic-img" class="img-wrap" style="height: 300px;width: 100%;  " [ngStyle]="{backgroundImage:'url(assets/images/paper-publish.webp)'}" >

                        </div>
                        <div id="mautic-link" class="links mt-auto d-flex justify-content-center">
                            <a href="https://sol.sbc.org.br/index.php/sbqs/article/view/15231" title="Detalhe do artigo" target="_BLANK"><i class="bx bx-zoom-in bx-md"></i></a>
                            <!--<a href="assets/images/compagne-detail.jpg" data-gall="MauticGallery" class="venobox vbox-item" title="Détail de la compagne"></a>
                            <a href="assets/images/email-detail.jpg" data-gall="MauticGallery" class="venobox vbox-item" title="Performance d'un émail"></a>
                            <a href="assets/images/asset-detail.jpg" data-gall="MauticGallery" class="venobox vbox-item" title="Performance d'un Asset"></a>-->
                            <a href="https://sol.sbc.org.br/index.php/sbqs/issue/view/734" title="Anais do Evento" target="_BLANK"><i class="bx bx-link bx-md"></i></a>
                        </div>
                    </div>
                    <div class="card-body">
                        <h3 class="card-title">Publicação</h3>
                        <h5 class="card-title"><strong>Um Comparativo na Execução de Testes Manuais e Testes de Aceitação Automatizados em uma Aplicação Web - SBQS 2015</strong>  </h5>
                        <p class="card-text">Java, Cucumber JVM, JUnit, Selenium WebDriver, PHP</p>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="https://youtu.be/oJ6RXq-7CT8" target="_blank" type="button" class="btn btn-primary mr-3">Video</a> 
                            <button (click)="detailOnClick('mauticMonitoring')"  type="button" class="btn btn-primary">Resumo</button>
                        </div>
                        <div *ngIf="mauticMonitoringIsShown" class="mt-3">
                            <p>Garantia da qualidade por meio de testes é essencial no processo de desenvolvimento de software. Entretanto, 
                                o alto custo da execução de testes manuais e o número cada vez maior de ambientes de testes web solicitados pelos 
                                clientes dificultam manter a qualidade do produto e cumprir o prazo estabelecido. Para solucionar este problema é proposto 
                                o uso da técnica Behavior-Driven Development em conjunto com Selenium WebDriver e JUnit. Este artigo apresenta um estudo de caso da 
                                técnica, relatando os ganhos obtidos como redução no esforço de execução de testes em uma aplicação web.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 
            <div class="col-lg-4 col-sm-12"  data-aos="flip-up">
                <div class="card shadow-lg p-3  bg-white rounded">
                    <div  class="card-header d-flex flex-column " style="height: 300px;width: 100%; "  >    
                        <div id="pokedex-img" class="img-wrap" style="height: 300px;width: 100%;  " [ngStyle]="{backgroundImage:'url(assets/images/pokedex.png)'}" >

                        </div>
                        <div id="pokedex-link" class="links mt-auto d-flex justify-content-center">
                            <a href="assets/images/pokedex.png" data-gall="pokedexGallery" class="venobox vbox-item" title="Pokedex"><i class="bx bx-zoom-in bx-md"></i></a>
                            <a href="assets/images/trainers.png" data-gall="pokedexGallery" class="venobox vbox-item" title="Trainers"></a>
                            <a href="assets/images/profile-trainer.png" data-gall="pokedexGallery" class="venobox vbox-item" title="Profile"></a>
                           
                            <a href="#portfolio" title="Indisponible" ><i class="bx bx-link bx-md"></i></a>
                        </div>
                    </div>
                   
                    <div class="card-body">
                        <h3 class="card-title">Pokedex</h3>
                        <h5 class="card-title"><strong>Technologies:</strong> </h5>
                        <p class="card-text">Java, J2EE, SpringBoot, TDD, MySQL, BootStrap, Services, Deploiment</p>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="https://github.com/ifi-2019/trainer-ui-zinedineBenkhider" target="_blank" type="button" class="btn btn-primary mr-3">Code Source</a> 
                            <button (click)="detailOnClick('pokedex')" type="button" class="btn btn-primary">+ Détail projet</button>
                        </div>
                        <div *ngIf="pokedexIsShown" class="mt-3">
                            <p>Affichage du détail des pokémons et de leurs drésseurs.</p>
                            <p>L'application contient deux micro-service: pokemon management et trainer management, qui exposent un canal de communication REST/JSON.<br>Le 3éme micro-service WEB-UI se connecte aux deux micro-service.</p>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div class="col-lg-4 col-sm-12"  data-aos="flip-up">
                <div class="card shadow-lg p-3 mb-5 bg-white rounded"> 
              
                    <div  class="header d-flex flex-column " style="height: 300px;width: 100%; "  >    
                        <div id="portfolio-img" class="img-wrap card-header" style="height: 300px;width: 100%;" [ngStyle]="{backgroundImage:'url(assets/images/portfolio.png)'}" >
                        </div>
                        <div id="portfolio-link" class="links d-flex justify-content-center" >
                            <a href="assets/images/portfolio.png" data-gall="portfolioGallery" class="venobox vbox-item" title="Portfolio"><i class="bx bx-zoom-in bx-md"></i></a>
                            <a href="https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640" title="Article" target="_BLANK"><i class="bx bx-link bx-md"></i></a>
             
                        </div>
                    </div>
                    <div class="card-body">
                        <h3 class="card-title">Portfolio</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Angular11, Html5, CSS, TypeScript, BootStrap</p>
                        
                        <div class="d-flex flex-row justify-content-center">
                            <a href="https://github.com/zinedineBenkhider/portfolio" target="_blank" type="button" class="btn btn-primary mr-3">Code Source</a> 
                            <button (click)="detailOnClick('portfolio')"  type="button" class="btn btn-primary">+ Détail projet</button>
                        </div>
                    
                        <div *ngIf="portfolioIsShown" class="mt-3">
                            <p>CV sous forme de site WEB responsive que j'ai mis en open-source sur GitHub accompagné d'une publication d'un <a title="Voir l'article" href="https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640" target="_blank">article</a> sur medium. 
                         </p>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div class="col-lg-4 col-sm-12"  data-aos="flip-up">
                <div class="card shadow-lg p-3 mb-5 bg-white rounded"> 
              
                    <div  class="header d-flex flex-column " style="height: 300px;width: 100%; "  >    
                        <div id="riddle-img" class="img-wrap card-header" style="height: 300px;width: 100%;" [ngStyle]="{backgroundImage:'url(assets/images/riddle.png)'}" >
                        </div>
                        <div id="riddle-link" class="links d-flex justify-content-center" >
                            <a href="assets/images/riddle.png" data-gall="riddleGallery" class="venobox vbox-item" title="riddle"><i class="bx bx-zoom-in bx-md"></i></a>
                            <a href="https://transfer-riddle-solver.herokuapp.com/index.html" title="Lien" target="_BLANK"><i class="bx bx-link bx-md"></i></a>
             
                        </div>
                    </div>
                    <div class="card-body">
                        <h3 class="card-title">Transfer Riddle</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java, J2EE(Web Services), JavaScript, Html5, CSS, BootStrap</p>
                        
                        <div class="d-flex flex-row justify-content-center">
                            <a href="https://github.com/zinedineBenkhider/transfer_riddle_client" target="_blank" type="button" class="btn btn-primary mr-3">Code Source</a> 
                            <button (click)="detailOnClick('riddleIsShown')"  type="button" class="btn btn-primary">+ Détail projet</button>
                        </div>
                    
                        <div *ngIf="riddleIsShown" class="mt-3">
                            <p> 
                                Affichage des étapes de la résolution d'énigme de transvasement. Étant donné deux réservoirs vides de contenance X et Y, comment obtenir précisément T litres dans l’un des deux réservoirs (T étant un entier).
                                On peut remplir ou vider un réservoir, et transvaser le contenu de l’un dans l’autre, jusqu’à ce que la source soit vide ou la destination pleine.
                         </p>
                        </div>
                    </div>
                </div>
            </div>
            -->
            
        </div>
    </div>
</section>